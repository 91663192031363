exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-data-js": () => import("./../../../src/pages/data.js" /* webpackChunkName: "component---src-pages-data-js" */),
  "component---src-pages-gene-hgnc-id-js": () => import("./../../../src/pages/gene/[hgnc_id].js" /* webpackChunkName: "component---src-pages-gene-hgnc-id-js" */),
  "component---src-pages-gene-js": () => import("./../../../src/pages/gene.js" /* webpackChunkName: "component---src-pages-gene-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-policies-affiliate-membership-policy-js": () => import("./../../../src/pages/policies/affiliate-membership-policy.js" /* webpackChunkName: "component---src-pages-policies-affiliate-membership-policy-js" */),
  "component---src-pages-policies-code-of-conduct-js": () => import("./../../../src/pages/policies/code-of-conduct.js" /* webpackChunkName: "component---src-pages-policies-code-of-conduct-js" */),
  "component---src-pages-policies-data-release-and-use-policy-js": () => import("./../../../src/pages/policies/data-release-and-use-policy.js" /* webpackChunkName: "component---src-pages-policies-data-release-and-use-policy-js" */),
  "component---src-pages-policies-human-genome-assembly-policy-js": () => import("./../../../src/pages/policies/human-genome-assembly-policy.js" /* webpackChunkName: "component---src-pages-policies-human-genome-assembly-policy-js" */),
  "component---src-pages-policies-index-js": () => import("./../../../src/pages/policies/index.js" /* webpackChunkName: "component---src-pages-policies-index-js" */),
  "component---src-pages-policies-publication-policy-js": () => import("./../../../src/pages/policies/publication-policy.js" /* webpackChunkName: "component---src-pages-policies-publication-policy-js" */),
  "component---src-pages-policies-software-sharing-policy-js": () => import("./../../../src/pages/policies/software-sharing-policy.js" /* webpackChunkName: "component---src-pages-policies-software-sharing-policy-js" */)
}

